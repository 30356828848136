.banner {
    background-image: url('../public/images/banner.svg');
    &__container {
        width: clamp(500px, 37vw, 620px);
    }
    &__title {
        font-size: clamp(25px, 3vw, 50px);
        &__small {
            font-size: clamp(20px, 3vw, 40px);
            }
    }
    &__subTitle {
        font-size: clamp(20px, 3vw, 40px);
    }
    &__img {
        position: absolute;
        bottom: 0;
        right: clamp(80px, 5vw, 197px);
        width: clamp(500px, 50vw, 830px);
    }
    &__input {
        // width: clamp(500px, 37vw, 620px);
        height: clamp(60px, 4vw, 72.66px);
        // border-radius: 10px 20px 20px 10px;
        padding-left: 28px;
    }
    &__search {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0 20px 20px 0;
    }
    @media (max-width: 430.5px) { 
        &__input {
            width: 292px;
            height: 35px;
            // border-radius: 5px 10px 10px 5px;
            padding-left: 13px;
        }
        &__search {
            position: absolute;
            bottom: 0;
            top: 0;
            border-radius: 0 10px 10px 0;
        }
    }
    
}
